import React, { useState } from "react";
import Header from "./components/Layout/Header";
import Arts from "./components/Arts/Arts";
import Cart from "./components/Cart/Cart";
import CartProvider from "./Store/CartProvider";

function App() {
  const [cartShown, setCartShown] = useState(false);

  function showCartHandler() {
    setCartShown(true);
  }

  function hideCartHandler() {
    setCartShown(false);
  }

  return (
    <CartProvider>
      {cartShown && <Cart onClose={hideCartHandler} />}
      <Header onShowCart={showCartHandler} />
      <main>
        <Arts></Arts>
      </main>
    </CartProvider>
  );
}

export default App;
