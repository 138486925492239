import ArtsSummary from "./ArtsSummary";
import AvailableArts from "./AvailableArts";

const Arts = (props) => {
  return (
    <>
      <ArtsSummary />
      <AvailableArts />
    </>
  );
};

export default Arts;
