import classes from "./ArtsSummary.module.css";

const ArtsSummary = () => {
  return (
    <section className={classes.summary}>
      <h2>Canvas Paintings Available for Sale!!!!!</h2>
      <p>Some of the exciting features comming soon...</p>
      <h4>Contact us</h4>
      <p>Call/WhatsApp - (+91) 7046910626</p>
      <p>
        Email -{" "}
        <a href="mailto:nareshart.in@gmail.com">nareshart.in@gmail.com</a>
      </p>
      <p>
        Instagram -{" "}
        <a href="https://www.instagram.com/nareshart.in/?hl=en">nareshart.in</a>
      </p>
    </section>
  );
};

export default ArtsSummary;
