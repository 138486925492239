import { useContext, useState } from "react";
import CartContext from "../../../Store/cart-context";
import classes from "./ArtItem.module.css";
//import image from "../../../assets/naturalImg.jpeg";
import ArtItemForm from "./ArtItemForm";

const ArtItem = (props) => {
  const price = `$${props.price.toFixed(2)}`;
  const cartCtx = useContext(CartContext);
  // const [image, setImage] = useState("");

  // (function (imageName) {
  //   import(imageName).then((image) => setImage(image.default));
  // })(props.imageURL);

  // const mealCartHandler = (enteredAmount) => {
  //   if (enteredAmount < 1 || enteredAmount > 5) {
  //     setInputIsValid(false);
  //   } else {
  //     setInputIsValid(true);
  //   }

  //   cartCtx.addItem({
  //     id: props.id,
  //     amount: +enteredAmount,
  //     name: props.name,
  //     price: props.price,
  //   });
  // };

  return (
    <li className={classes.meal}>
      <div>
        <h3>{props.name}</h3>
        <div>{props.description}</div>
        {/* <div>{price}</div> */}
        <a href={props.imageURL}>
          <img
            src={props.imageURL}
            className={classes.center + " " + classes.artImage}
            alt={props.name}
          />
        </a>
      </div>
      <div>
        {/* <ArtItemForm id={props.id} onArtAdded={mealCartHandler} />
        {!inputIsValid && <p>Please enter value between 1-5.</p>} */}
      </div>
    </li>
  );
};

export default ArtItem;
