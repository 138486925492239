import classes from "./AvailableArts.module.css";
import ArtItem from "./ArtItem/ArtItem";
import Card from "../UI/Card";

const DUMMY_MEALS = [
  {
    id: "a1",
    name: "Moon and The Dreams!!",
    description: "Moon and The Dreams!!",
    imageURL: "/moonImg.jpeg",
    price: 16.5,
  },
  {
    id: "a2",
    name: "Flowers & Birds!!",
    description: "Flowers & Birds!!",
    imageURL: "/birdsImg.jpeg",
    price: 16.5,
  },
  // {
  //   id: "a3",
  //   name: "Almighty Shiva",
  //   description: "Almighty Shiva",
  //   imageURL: "",
  //   price: 12.99,
  // },
  {
    id: "a4",
    name: "Village Girl & Mother!!",
    description: "Village Girl & Mother!!",
    imageURL: "/villageImg.jpeg",
    price: 18.99,
  },
  {
    id: "a5",
    name: "Divine Hands!!",
    description: "",
    imageURL: "/divineHandsImg.jpeg",
    price: 12.99,
  },
  {
    id: "a6",
    name: "Natural!!",
    description: "",
    imageURL: "/naturalImg.jpeg",
    price: 12.99,
  },
  {
    id: "a7",
    name: "Mira !!",
    description: "",
    imageURL: "/krishnaImg.jpeg",
    price: 12.99,
  },
  {
    id: "a8",
    name: "Ganesha!!",
    description: "",
    imageURL: "/ganeshaImg.jpeg",
    price: 12.99,
  },
  {
    id: "a11",
    name: "Girl with Pot!!",
    description: "",
    imageURL: "/girlPotImg.jpeg",
    price: 12.99,
  },
  {
    id: "a12",
    name: "Farmer Girl!!",
    description: "",
    imageURL: "/harvestingImg.jpeg",
    price: 12.99,
  },
];

const AvailableArts = (props) => {
  const mealsList = DUMMY_MEALS.map((meal) => (
    <ArtItem
      key={meal.id}
      name={meal.name}
      description={meal.description}
      price={meal.price}
      imageURL={meal.imageURL}
      id={meal.id}
    />
  ));

  return (
    <section className={classes.meals}>
      <Card>
        <ul>{mealsList}</ul>
      </Card>
    </section>
  );
};

export default AvailableArts;
