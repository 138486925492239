import classes from "./Header.module.css";
import HeaderCartButton from "./HeaderCartButton";
import NareshArtLogo from "../../assets/Naresh_Art_Logo.png";

const Header = (props) => {
  return (
    <>
      <header className={classes.header}>
        <img src={NareshArtLogo} alt="NARESH ART"></img>
        <img className={classes.colorPallet} src={"/color-palette.png"}></img>
        {/* <HeaderCartButton onClick={props.onShowCart} /> */}
        <nav>
          <text>{/* <a>Home</a> */}</text>
        </nav>
      </header>

      <div className={classes["main-image"]}>
        {/* <img src={background}></img> */}
      </div>
    </>
  );
};

export default Header;
